function initLatestParts () {
  document.querySelectorAll('.latest-parts').forEach((root) => {
    initButtons(root);
    setHeight(root);
  });
}

function initButtons (root) {
  root.querySelectorAll('[data-group]').forEach((group) => {
    const scrollArea = group.querySelector('[data-scrollable-area]');
    const btn = group.querySelectorAll('[data-scroll]');
    const items = group.querySelectorAll('[data-index]');
    const itemCount = items.length;
    let currentScrollPos = 0;

    // disable prev/next buttons when only one item
    if (itemCount <= 1) {
      group.querySelectorAll('[data-scroll]')
        .forEach(button => button.setAttribute('disabled', ''));
    }

    // listen for scroll and track current position
    scrollArea.addEventListener('scroll', (e) => {
      const { target } = e;
      currentScrollPos = target.scrollTop;
    });

    scrollArea.scrollTo({ top: 0, left: 0 });

    btn.forEach((b) => {
      b.onclick = (e) => {
        const { currentTarget } = e;
        const amount = Number(currentTarget.getAttribute('data-scroll'));
        let currentIndex = Number(scrollArea.getAttribute('data-current-index'));

        currentIndex += amount;
        currentIndex = Math.min(Math.max(0, currentIndex), itemCount - 1);

        const item = items[currentIndex];
        const targetScrollTop = item.offsetTop;

        if (amount > 0 && targetScrollTop > currentScrollPos) {
          scrollArea.scrollTo({ top: targetScrollTop, left: 0, behavior: 'smooth' });
        } else if (amount < 0 && targetScrollTop < currentScrollPos) {
          scrollArea.scrollTo({ top: targetScrollTop, left: 0, behavior: 'smooth' });
        }

        scrollArea.setAttribute('data-current-index', String(currentIndex));

        // re-enable prev/next buttons
        group.querySelector('[data-scroll]').removeAttribute('disabled');

        // disable prev/next buttons based on index
        group.querySelectorAll('[data-scroll]').forEach((button) => {
          if (currentIndex === 0 && button.dataset.scroll === '-1') {
            button.setAttribute('disabled', '');
          } else if (currentIndex === itemCount - 1 && button.dataset.scroll === '1') {
            button.setAttribute('disabled', '');
          } else {
            button.removeAttribute('disabled');
          }
        });
      };
    });
  });
}

function setHeight (root) {
  let maxCardHeight = 0;
  root.querySelectorAll('[data-index]').forEach((card) => {
    // adjust  height to the maximum card height
    maxCardHeight = Math.max(Math.round(card.offsetHeight), maxCardHeight);
  });
  root.querySelectorAll('[data-group]').forEach((group) => {
    // adjust scrollable area height to the maximum card height
    const scrollArea = group.querySelector('[data-scrollable-area]');
    scrollArea.style.height = `${maxCardHeight + 1}px`;
    group.querySelectorAll('[data-index]').forEach((card) => {
      card.style.height = `${maxCardHeight}px`;
    });
  });
}

document.addEventListener('DOMContentLoaded', () => {
  initLatestParts();
});